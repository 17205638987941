import React, { useEffect } from "react";
import { Globals } from "react-spring";
import { BaseLayout, useReduceMotion } from "gatsby-theme-jqi";

const Provider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const menuItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Research",
      url: "/research",
    },
    {
      title: "People",
      url: "/people",
    },
    {
      title: "News",
      url: "/news",
    },
    {
      title: "Publications",
      url: "/publications",
    },
    {
      title: "Theses",
      url: "/theses",
    }
  ] as JQI.MenuItem[];
  const headerProps = {
    logo: {
      src: "/logo_gorshkov.svg",
      alt: "Joint Quantum Institute Research - Gorshkov Group",
      width: 584,
      height: 100,
    },
    menuItems,
  } as JQI.SiteHeaderProps;
  const footerProps = {
    menuItems,
    contact: {
      email: "gorshkov@umd.edu"
    }
  } as JQI.SiteFooterProps;
  // Disable React Spring animations based on user's OS-level reduced motion preference.
  const prefersReducedMotion = useReduceMotion() as boolean;
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion]);
  return (
    <>
      <BaseLayout headerProps={headerProps} footerProps={footerProps}>
        {children}
      </BaseLayout>
    </>
  );
};

export default Provider;
