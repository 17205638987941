import React from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import "./ArticleFeedItem.scss";
import { BasicLink, useHover } from "gatsby-theme-jqi";

const ArticleFeedItem: React.FC<JQI.ArticleFeedItemProps> = ({
  date,
  title,
  fields,
  body,
  field_sites,
  sites,
  relationships,
  hideDescription,
  hideImage,
  hideDate,
}) => {
  const siteList = sites || field_sites;
  // If this news item is owned by the parent, render things normally
  let ownedByParent = false;
  siteList.forEach((site) => {
    if (site.drupal_internal__target_id === 24) {
      ownedByParent = true;
    }
  })

  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "article-feed-item": true,
    "article-feed-item--large": !hideDescription,
    "article-feed-item--small": hideDescription,
    "local": !ownedByParent,
    hovered,
  });
  const imageData = relationships.hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: relationships.hero?.relationships.heroImage?.mediaImage.alt || "",
  } as GatsbyImageProps;
  return (
    <article className={classList} onClick={ownedByParent ? () => { navigate(fields.pagePath) }: ()=> {}} ref={hoverRef}>
      {
        (!hideImage && !!imageProps.image) &&
        <GatsbyImage className="article-feed-item__image" {...imageProps} />
      }
      <div className="article-feed-item__content">
        <h2 className="article-feed-item__title">
          {
            !!fields?.pagePath
              ? <BasicLink url={fields.pagePath}>{title}</BasicLink>
              : <span>{title}</span>
          }
        </h2>
        {
          !hideDate &&
          <div className="article-feed-item__date">{date}</div>
        }
        {
          (!!body && !hideDescription) &&
          <p className="article-feed-item__teaser text-content" dangerouslySetInnerHTML={{ __html: ownedByParent ? body.summary : body.processed }}></p>
        }
      </div>
    </article>
  );
};

export default ArticleFeedItem;
